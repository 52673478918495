.login {
  width: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .submit {
    text-align: right;
  }
  .logo {
    max-width: 100%;
  }
}
