.saving {
  top: 24px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  z-index: 100;
  position: fixed !important;

  display: block !important;
  min-width: 100px;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0 !important;
  font-size: 1em;

  &__close_icon {
    margin-right: 0 !important;
    margin-left: .6em !important;
  }
}