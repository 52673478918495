.header-button {
  &.ui.button {
    line-height: 1.2;
    padding-top: 13px;
    padding-bottom: 13px;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15) !important;
    color: #000000;
    font-weight: normal;
    background-color: #ffffff;
  }
  &.ui.button:focus {
    background-color: #ffffff;
  }
  &.ui.button:hover,
  &.ui.button:hover:focus {
    background-color: #efefef;
  }
  &.ui.button:active,
  &.ui.button:active:focus {
    background-color: #dddddd;
  }
}
