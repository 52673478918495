.form_rate_list {
  position: relative;

  &__header_cell_id {
    width: 90px;
  }

  &__header_cell_country {
    width: 140px;
  }

  &__header_cell_currency_from {
    width: 140px;
  }

  &__header_researcher_name {
    width: 140px;
  }

  &__header_client_type {
    width: 140px;
  }

  &__header_status {
    width: 100px;
  }

  &__header_cell_actions {
    width: 110px;
  }
}