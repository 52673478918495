.snackbar {
  min-width: 100px;
  border-left: solid 3px;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0 !important;
  font-size: 1em;
  position: absolute !important;
  z-index: 100;
  margin: 1em !important;
  left: 1em;
  bottom: 1em;

  &__close_icon {
    margin-right: 0 !important;
    margin-left: .6em !important;
  }
}