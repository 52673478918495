$submittedColor: rgba(33,186,69,.4);
$notSubmittedColor: rgba(34,36,38,.15);
$cantBeSubmitColor: rgba(219,40,40,.4);

.form_collect__rect {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.35em;
  vertical-align: middle;

  &-submitted {
    background-color: $submittedColor;
  }
  &-cant_be_submit {
    background-color: $cantBeSubmitColor;
  }
  &-not_submitted {
    background-color: $notSubmittedColor;
  }
}

.form_collect__amount_cell {
  max-width: 100px;
}

.form_collect__collected_at_cell {
  width: 260px;
}

.form_collect__collected_at_header {
  min-width: 155px;
}

.form_collect__cell-submitted .ui.input > input {
  border-color: $submittedColor !important;
}

.form_collect__cell-cant_be_submit .ui.input > input {
  border-color: $cantBeSubmitColor !important;
}

.form_collect__table_container {
  overflow-y: auto;
  margin: 1em 0;
}