.ui.table.sortable-table {
  thead {
    th.sortable {
      cursor: pointer;
      padding-right: 20px;
      position: relative;
    }
    th.sortable:hover {
      background: #edeeef;
    }
    th.sortable:after {
      display: block;
      position: absolute;
      width: 20px;
      line-height: 1;
      text-align: center;
      content: "\f0dc";
      font-family: 'Icons', sans-serif;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    th.sortable.asc:after {
      content: "\f0de";
    }
    th.sortable.desc:after {
      content: "\f0dd";
    }
  }
  tbody {
    td {
      overflow: visible;
    }
  }
}
