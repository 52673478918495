$submittedColor: rgba(33,186,69,.4);
$notSubmittedColor: rgba(34,36,38,.15);
$cantBeSubmitColor: rgba(219,40,40,.15);

.crypto_form_collect__collected_at_cell {
  width: 260px;
}

.crypto_form_collect__collected_at_header {
  min-width: 155px;
}

.crypto_form_collect__table_container {
  z-index: 2;
}

.crypto_form_collect__table_actions {
  padding: 0 1em;
  position: absolute;
}

.crypto_form_collect__table {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.crypto_form_collect__configuration_header {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  flex: auto;
}

.crypto_form_collect__corridor_header {
  background: #f3f4f5;
  display: flex;
  align-items: center;
}

.crypto_form_collect__corridor_expand {
  position: relative;
  padding: 0 0.3rem;
  cursor: pointer;
  transform: rotate(-90deg);

  &--expanded {
    left: 2px;
    transform: none;
  }
}

.crypto_form_collect__row {
  & > td {
    min-width: 110px;
    vertical-align: top;
    &.crypto_form_collect__amount_cell--RANGE {
      min-width: 230px;
    }
    &.crypto_form_collect__amount_cell--SINGLE {
      min-width: 100px;
      max-width: 160px;
    }
    &.crypto_form_collect__fee_value_cell {
      width: 510px;
    }
    &.crypto_form_collect__collected_at_cell {
      width: 200px;
    }
  }
  & > td input {
    width: 100% !important;
  }
  & > td .ui.selection.dropdown {
    min-width: 4em;
  }

  &--cant_be_submit {
    background-color: $cantBeSubmitColor;
  }
}

.crypto_form_collect__fee_amount_range {
  display: flex;
}

.crypto_form_collect__fee_amount_range_currency > div {
  padding: .67857143em 0 .67857143em 1em;
}

.crypto_form_collect__fee_value_container {
  display: inline-flex;
}

.crypto_form_collect__fee_value_input {
  display: flex;
  width: 152px;
  margin-left: 1em;
  & > .field {
    flex: 1 0 auto;
  }
}

.crypto_form_collect__fee_dynamic_value_input {
  display: flex;

  & > .crypto_form_collect__fee_value_amount {
    width: 65px;
  }
}

.crypto_form_collect__fee_value_amount {
  max-width: 75px;
}

.crypto_form_collect__fee_value_add {
  cursor: pointer;
  display: inline;
}

.crypto_form_collect__fee_value_controls {
  margin-top: 23px;
  margin-left: 1em;
}

.crypto_form_collect__controls_cell {
  & > button {
    margin-bottom: 1em !important;
  }
  & > button:last-child {
    margin-bottom: 0 !important;
  }
}

.crypto_form_collect__fee_value_type {
  min-width: 135px;
}

.crypto_form_collect__fee_currency {
  width: 77px;
  max-width: 77px;
  min-width: 77px;
}

.crypto_form_collect__fee_value_percent {
  width: 75px;
  margin-left: 1em !important;
}

.crypto_form_collect__corridor_footer {
  padding: 0 1em 1em;
}

.crypto_form_collect__corridor_online_url_input {
  position: absolute;
  width: 100%;
  top: -60px;
}

.crypto_form_collect__online_urls_container {
  td:nth-child(2) {
    width: 200px;
  }
  td:nth-child(3) {
    width: 1px;
  }
}

.crypto_form_collect__add_online_url {
  cursor: pointer;
}

.crypto_form_collect__input_field--width_popup_error {
  position: relative;

  & > .ui.pointing {
    position: absolute !important;
    top: calc(100% - 5px);
    left: 0;
  }
}

.crypto_form_collect__fee_value_amount {
  & > .ui.pointing {
    min-width: 120px;
    margin-top: 7px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.crypto_form_collect__fee_value_percent {
  & > .ui.pointing {
    min-width: 120px;
    margin-top: 7px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.crypto_form_collect__rate_value_amount {
  max-width: 150px;
}

.crypto_form_collect--zIndex3 {
  z-index: 3
}