.image-attachments {
  &.list {
    margin-top: 0;
  }

  .item {
    display: flex !important;
    justify-content: space-between;

    .remove {
      margin-left: 1rem;
    }

    span, a {
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      white-space: nowrap;
      overflow: hidden;

      width: 100%;
      border: 1px solid #dddddd;
      padding: 0.7rem;
      display: inline-block;
      border-radius: 0.28rem;
    }
  }
}
