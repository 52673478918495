.flex {
  display: flex !important;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.ui.pagination.menu {
  .item:focus {
    outline: none;
  }
}

.overflow-elipsis {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.hidden {
  display: none !important;
}

.spaced-items {
  &>* {
    margin-right: 1rem !important;
  }
  &>*:last-child {
    margin-right: 0 !important;
  }
}

.link-like {
  color: #4183c4;
  cursor: pointer;
}
