.date_time_picker__fields {
  display: flex;
  width: 100%;
}

.date_time_picker__fields > * {
  margin-bottom: 0 !important;
}

.date_time_picker__fields > :nth-child(1) {
  width: 100%;
}