.search-input {
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  border-radius: .28571429rem;
  &.ui.input>input {
    border: none;
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 1.2;
  }
  &.ui.input>button {
    border: none;
    border-radius: 0 !important;
  }
  &.ui.input>button:active,
  &.ui.input>button:focus {
    background: #e0e1e2 none;
  }
}
